<!--用户用电量排名-->
<template>
    <widget-framework v-if="dataLoaded" title="用户用电量排名">
        <template #header>
            <div class="headerContainer">
                <span @click="$router.push('/business/customerAccount')">用户用电量排名</span>
                <el-date-picker
                    v-model="month"
                    placeholder="选择月份"
                    type="month"
                    value-format="yyyy-MM"
                    style="width: 110px"
                    :clearable="false"
                    @change="monthChange"
                >
                </el-date-picker>
            </div>
        </template>
        <template #content>
            <dv-scroll-board :config="config" ref="scrollBoard" class="board"/>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import WidgetBase from '@/components/widgets/WidgetBase';
import numeral from 'numeral';
import {headerBGC, oddRowBGC, evenRowBGC, yellow, red, cyan, green} from '../constant';
import dayjs from 'dayjs';

export default {
    name: 'PowerConsumptionRanking',
    components: {
        WidgetFramework
    },
    mixins: [WidgetBase],
    data() {
        const month = dayjs().subtract(1, 'month').format('YYYY-MM');
        return {
            dataLoaded: false,
            config: {
                header: ['用户名称', '用电量', '同比', '环比'],
                headerBGC,
                oddRowBGC,
                evenRowBGC,
                data: [],
                index: true,
                indexHeader: '',
                columnWidth: [40, 200, 150, 100, 100],
                align: ['center'],
                carousel: 'single',
                rowNum: 6,
            },
            month
        };
    },
    methods: {
        formatterAmount(value) {
            return (value == null || value === undefined) ? '~' : value + 'kWH';
        },
        formatterRate(value, showPlus = false) {
            if (showPlus) {
                return (value == null || value === undefined) ? '~' : numeral(value).format('+0.0%');
            }
            return (value == null || value === undefined) ? '~' : numeral(value).format('0.0%');
        },
        monthChange(month) {
            this.powerConsumptionRanking(month);
        },
        powerConsumptionRanking(month, firstRender) {
            this.$client.powerConsumptionRanking({month}).then((ret) => {
                const {data = []} = ret;
                const items = data.map(item => {
                    const name = `<span style="color: ${cyan}">${item.customerName}</span>`;
                    const saleQuantity = `<span style="color: ${cyan}">${this.formatterAmount(item.saleQuantity)}</span>`;
                    let saleQuantityYoY;
                    if (item.saleQuantityYoY > 0) {
                        saleQuantityYoY = `<span style="color: ${yellow}">${this.formatterRate(item.saleQuantityYoY, true)}</span>`;
                    } else if (item.rateOfYearOnYear < 0) {
                        saleQuantityYoY = `<span style="color: ${red}">${this.formatterRate(item.saleQuantityYoY, true)}</span>`;
                    } else {
                        saleQuantityYoY = `<span style="color: ${green}">${this.formatterRate(item.saleQuantityYoY, true)}</span>`;
                    }

                    let saleQuantityMoM;
                    if (item.saleQuantityMoM > 0) {
                        saleQuantityMoM = `<span style="color: ${yellow}">${this.formatterRate(item.saleQuantityMoM, true)}</span>`;
                    } else if (item.saleQuantityMoM < 0) {
                        saleQuantityMoM = `<span style="color: ${red}">${this.formatterRate(item.saleQuantityMoM, true)}</span>`;
                    } else {
                        saleQuantityMoM = `<span style="color: ${green}">${this.formatterRate(item.saleQuantityMoM, true)}</span>`;
                    }

                    return [name, saleQuantity, saleQuantityYoY, saleQuantityMoM];
                });
                if (firstRender) {
                    this.config.data = items;
                } else {
                    this.$refs.scrollBoard.updateRows(items);
                }
                this.dataLoaded = true;
            });
        }
    },
    mounted() {
        this.$client.getIsPowerConsumptionRankingOfMonth().then(data => {
            this.month = data.data;
            this.powerConsumptionRanking(this.month, true);
        });
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.board {
    width: 100%;
    height: 100%;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);

    /deep/ .el-input__inner {
        border: 1px solid @lightBlue;
        background-color: rgba(0, 204, 255, 0.2);
        color: @cyan;

        input {
            background-color: unset;
            color: @cyan;
        }
    }
}

</style>
